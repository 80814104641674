import React from 'react';
import Wrapper from "../../hoc/Wrapper";
import Header from "./header/Header";
import Footer from "./footer/Footer";
const Layout = (props) => {
    return (
        <Wrapper>
            <Header />
            <main>{props.children}</main>
            <Footer />
        </Wrapper>
    );
};

export default Layout;