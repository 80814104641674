import React from 'react';
import './App.css';
import Layout from "./components/layout/Layout";
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from "./utils/ScrollToTop";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
            <div className="App">
                <Layout>
                    {/*<Home/>*/}
                    {/*<Brands/>*/}
                    {/*<Contact/>*/}
                </Layout>
            </div>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
