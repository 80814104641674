import React, { Component } from 'react';
import { Link } from "react-router-dom";
class Footer extends Component {
    getYear() {
        let d = new Date();
        return d.getFullYear()
    }
    render() {
        return (
            <footer>
                <div className="footer_row">
                    <div className="left">
                        <Link to="/about" >About Us</Link>
                        <Link to="/brands" >Our Brands</Link>
                        <Link to="/contact" >Contact Us</Link>
                        <Link to="/media" >Media</Link>
                        <a href="https://www.healthkart.com/hk/Careers.action" target={"_blank"} >Careers</a>
                    </div>
                    <div className="right">
                        <p>Bright Life Care Private Limited</p>
                        <div className="copyright">Copyright © {this.getYear()}, blife.in, or its affiliates</div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default Footer;
